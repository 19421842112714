import { CartLine } from '@lambda/commons/apis/shopify/types/storefront';
import shopify from '@/lib/shopify';
import settings from '@/settings';
import { CartItemAttribute } from './use-cart';

const getLastestProductQuantityUsingHandle = async (handle: string) => {
  const response = await shopify.fetch({
    query: `query ($handle: String!) {
              product(handle: $handle) {
                variants(first:1){ edges { node {
                  id availableForSale quantityAvailable
                } } }
              }
            }`,
    variables: { handle },
  });
  const { product } = response;

  return {
    quantityAvailable:
      product?.variants?.edges?.[0]?.node?.quantityAvailable || 0,
    availableForSale: product?.variants?.edges?.[0]?.node?.availableForSale,
  };
};

export const SHOPIFY_CART_QUERY = `
id checkoutUrl
attributes { key value }
discountCodes { code applicable }
estimatedCost { totalAmount { amount } subtotalAmount { amount } totalTaxAmount { amount } }
lines(first: 10) { edges { node {
  id quantity attributes { key value }
  merchandise { ... on ProductVariant {
      id sku quantityAvailable title
      image { url }
      priceV2 { amount }
      compareAtPriceV2 {amount}
      product { title, tags, vendor, onlineStoreUrl, handle, productType}
  } }
} } }`;

export const deleteCartLines = async (
  lineIds: Array<string>,
  cartId: string,
) => {
  const response = await shopify.fetchSafe({
    query: `mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
                cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
                  cart { ${SHOPIFY_CART_QUERY} }
                  userErrors { field message }
                }
              }`,
    variables: { cartId, lineIds },
  });

  return response?.cartLinesRemove.cart;
};

export const findOutOfStockCartLineItems = async (lineItems: CartLine[]) => {
  const toBeRemovedCartLineItems: string[] = [];

  const unavailableLineItems = await Promise.all(
    lineItems.map(async (lineItem) => {
      const { title } = lineItem.merchandise.product;

      if (
        title.toLowerCase().indexOf('reebelocare') > -1 ||
        title.toLowerCase().indexOf('express shipping') > -1 ||
        title.toLowerCase().indexOf('reebelo\'s gift card') > -1
      )
        return undefined;

      const { quantityAvailable, availableForSale } =
        await getLastestProductQuantityUsingHandle(
          lineItem.merchandise.product.handle,
        );

      if (quantityAvailable < 1 || !availableForSale) {
        return {
          id: lineItem.id,
          sku: lineItem.merchandise.sku,
          vendor: lineItem.merchandise.product.vendor,
        };
      }

      return undefined;
    }),
  );

  /*
   * RT-4875
   * Check for the merchandise to be removed
   */
  if (unavailableLineItems.filter(Boolean).length > 0) {
    unavailableLineItems.forEach((unavailableLineItem) => {
      if (unavailableLineItem != null) {
        // Add the to be removed merchandise into the array
        toBeRemovedCartLineItems.push(unavailableLineItem.id);
        const offerKey = `${settings.store}#${unavailableLineItem.vendor}#${unavailableLineItem.sku}`;

        // Find out reebelocare / express shipping item to be removed
        lineItems.forEach((lineItem) => {
          const { title } = lineItem.merchandise.product;

          if (
            title.toLowerCase().indexOf('reebelocare') > -1 ||
            title.toLowerCase().indexOf('express shipping') > -1
          ) {
            const { attributes } = lineItem;
            const tobeRemove = attributes.some(
              (attribute: CartItemAttribute) =>
                attribute.key === 'ID' && attribute.value === offerKey,
            );

            if (tobeRemove) toBeRemovedCartLineItems.push(lineItem.id);
          }
        });
      }
    });
  }

  return toBeRemovedCartLineItems;
};
