import cn from 'classnames';
import type React from 'react';
import { forwardRef } from 'react';
import Loading from './Loading';

type ButtonDefaultAttributes = React.ButtonHTMLAttributes<HTMLButtonElement>;

type LinkDefaultAttributes = React.AnchorHTMLAttributes<HTMLAnchorElement>;

type CustomProps = {
  loading?: boolean;
  variant?: 'blue' | 'dark' | 'white' | 'teal' | 'outlined' | 'red';
  isOutlined?: boolean;
};

const BASE_CLASSES =
  'r-font-bold r-p-0 r-h-11 xxs:r-h-auto r-text-sm xxs:r-text-base xxs:r-p-3 r-my-1 r-rounded-md r-duration-200 r-ease-in-out hover:r-opacity-90 disabled:r-opacity-70';
const DYNAMIC_STYLES = 'hover:r-shadow-lg';
const DARK = 'r-text-white r-bg-gray-700';
const BLUE = 'r-text-white r-bg-blue-500';
const TEAL = 'r-text-gray-700  r-bg-teal-500';
const WHITE = 'r-text-gray-700 r-bg-white';
const RED = 'r-text-white r-bg-link-red-700';
const OUTLINED = 'r-text-gray-700 r-border r-bordegray-700 bg-transparent';
const computedClasses = (variant: CustomProps['variant']) =>
  cn(BASE_CLASSES, DYNAMIC_STYLES, {
    [DARK]: variant === 'dark' || variant == null,
    [BLUE]: variant === 'blue',
    [WHITE]: variant === 'white',
    [TEAL]: variant === 'teal',
    [RED]: variant === 'red',
    [OUTLINED]: variant === 'outlined',
  });

export const CtaButton = forwardRef(
  (props: ButtonDefaultAttributes & CustomProps, ref) => {
    const { loading, className, variant, isOutlined, ...buttonProps } = props;
    const computedClassNames = isOutlined ? OUTLINED : computedClasses(variant);

    return (
      <button
        ref={ref as any}
        {...buttonProps}
        className={cn(className, computedClassNames)}
        disabled={loading === true || props.disabled}
      >
        {props.loading ? <Loading /> : props.children}
      </button>
    );
  },
);

export const CtaLink = forwardRef(
  (props: LinkDefaultAttributes & CustomProps, ref) => {
    const { loading, className, variant, ...linkProps } = props;

    return (
      <a
        ref={ref as any}
        {...linkProps}
        className={cn('text-center', className, computedClasses(variant))}
      >
        {loading ? <Loading /> : props.children}
      </a>
    );
  },
);
